@import '../base/settings';

.contentViewBox {
  display: flex;
  flex-direction: column;
  width:650px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 40px);
  position: relative;
  color: black;
  padding-right: 2vw;

}

.contentViewBox:hover {
  display: flex;
  flex-direction: column;
  width:650px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 40px);
  position: relative;
  color: black;
  padding-right: 2vw;


  #contentTitle{
    margin:0;
    background-color: $soft-black2;
    color: $white;
    border:1px solid $medium-grey;
    padding-left: 10px;
    font-size: 15px;
    width:440px;
    display: flex;
    flex-wrap: wrap;
    min-height: 25px;
  }

}

#nodeViewContainer{
  width:630px;
  background-color: white;
  border:1px solid $medium-grey;
  top: -1px;
  position: relative;
  box-sizing: content-box;
  margin-bottom: 30px;
}

#linkContainer{
  width: 600px;
  border-left: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  box-sizing: border-box;
  margin: 10px;
}
#linkHeader{
  font-size: 18px;
}
#linkEventHeader{
  font-size: 25px;
}
#linkDesc{
  font-size: 12px;
  color: $desc-gray;
}
#linkSource{
  font-size: 14px;
  color: $desc-gray;
}
#linkClickthrough{
  border: 1px solid $primary-color;
  border-radius: 3px;
  height: 70px;
  width: 100px;
  transition-duration: 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  box-sizing: border-box;
  img{
    height: 25px;
    width:25px;
  }
  div{
    height:15px;
    width: 15px;
    background-color: $primary-color-complement;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    left:21px;
    top:26px;
    position: relative;
    transition-duration: 0.3s;

    img{
      height: 10px;
      width:10px;
    }
  }
}

#linkClickthrough:hover{
  border: 1px solid $soft-black2;
  border-radius: 3px;
  height: 70px;
  width: 100px;
  transition-duration: 0.3s;
  div{
    height:15px;
    width: 15px;
    background-color: $soft-black2;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    left:21px;
    top:26px;
    position: relative;
    img{
      height: 10px;
      width:10px;
    }
  }
}

#linkTextContent{
  width: 480px;
  display: flex;
  flex-direction: column;
}


.linkBanner{
  width: 600px;
  height: auto;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-bottom: 15px;

  img{
    width:600px;
    height: auto;
    background-image: url('/public/images/imageDefault.jpg');
    background-size: contain;
    text-align: center;
    color:white;
  }
  overflow: hidden;
}

#contentContainer{
  padding: 10px;
  div{
    a.linkUrl{
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
      max-height: 16px;

      color:$primary-color;
    }
    a.linkUrl:hover{
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
      max-height: 16px;

      color:$soft-black2;
    }

      .timelineClickthrough{
        width: 90px;
        height: 35px;
        background: $primary-color;
        box-sizing: border-box;
        border: none;
        color: white;
        margin:5px;
        margin-top: 0px;
        border-radius: 2px;
        padding-top: 10px;
        font-family: $primary-font;
        font-size: 12px;
        text-align: center;
        transition-duration: 0.3s;
      }

      .timelineClickthrough:hover{
        padding-top: 10px;
        box-sizing: border-box;
        margin:5px;
        margin-top: 0px;
        width: 90px;
        height: 35px;
        border-radius: 2px;
        border: 1px solid $dark-grey;
        color:white;
        background: $primary-color;
        font-family: $primary-font;
        font-size: 12px;
        text-align: center;
      }

  }
}

#contentTitle {
  margin:0;
  background-color: white;
  border:1px solid $medium-grey;
  padding-left: 10px;
  font-size: 15px;
  width:440px;
  display: flex;
  flex-wrap: wrap;
    min-height: 25px;
  transition-duration: .3s;
  -webkit-transition-duration: .3s;
  -moz-transition-duration: .3s;
  -ms-transition-duration: .3s;
  -o-transition-duration: .3s;

}

.contentViewImage{
  height: auto;
  width: auto;
  max-width:  600px;
  max-height: 500px;
}

#timelineClickthroughViewing{
  background: $soft-green;
  color:black;
  border:none;
  width: 90px;
  height: 35px;
  margin:5px;
  margin-top: 0px;
  border-radius: 2px;
  font-family: $primary-font;
  font-size: 12px;
  text-align: center;
  transition-duration: 0.3s;
}
.nodeInfoContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: white;
  width: 100px;
  top:-80px;
  border-radius: 2px;
  border: 1px solid $dark-grey;
  font-family: $secondary-font;
  font-size: 12px;
  position:relative;
}

.commonButtonNodeContainer{
  position:relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height:80px;
  background: none;
  border-radius: 4px;
}

.nodeDescription{
  max-width: 580px;
  word-wrap: break-word;
  top: -70px;
  margin:20px;
  margin-top:0;
  font-size:14.5px;
  position:relative;
  padding-left:5px;
  border-left: 1px solid $dark-grey;
}
.nodeDescriptionAlt{
  max-width: 580px;
  word-wrap: break-word;
  margin:20px;
  margin-top:0;
  font-size:14.5px;
  position:relative;
  padding-left:5px;
  border-left: 1px solid $dark-grey;

}

.nodeRefSource{
  max-width: 580px;
  word-wrap: break-word;
  padding:5px;
  border-left: 1px solid $dark-grey;
  margin-left:20px;
  margin-bottom:30px;
  font-size:12px;

}


.subscribeTimelineButton{
    width: 90px;
    height: 35px;
    background: $soft-black2;
    border: none;
    color: $soft-white;
    margin:5px;
    margin-top: 0px;
    border-radius: 2px;
    font-family: $primary-font;
    font-size: 12px;
    text-align: center;
    transition-duration: 0.3s;

}
.subscribeTimelineButton:hover{
  margin:5px;
  margin-top: 0px;
  width: 90px;

  height: 35px;
  border-radius: 2px;
  border: none;
  color:white;
  background: $primary-color;
  font-family: $primary-font;
  font-size: 12px;
  text-align: center;
  transition-duration: 0.3s;

}

#buttonSubscribed{

}

.commonButtonNodeContainerHidden{
  position:relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height:80px;
  width: 100px;
  top: -20px;
  left:350px;
}

.bookmark-icon{
  fill: $primary-color;
  transition-duration: .3s;
  height:50px;

}
.bookmark-icon:hover{
  fill: $soft-black2;
  height:50px;
}
#left-timeline-banner-icon{
  position: relative;
  bottom: 8px;
  left:40px;
}
#right-timeline-banner-icon{
  position: relative;
  bottom: 8px;
  left:115px
}

.timelineNodeViewButtonContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height:80px;
  width:100%;

}

#timelineContentContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height:80px;
  width:100%;
}
#cNodeContentContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80px;
  width: 520px;
  #cNodeContentContainerSub{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 80px;
    width: 120px;
  }
}

#nodeViewTokenVoteContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height:80px;
  align-items: center;
  margin-right: 5px;
  button{
    background: none;
    border:none;
    width: 30px;
    height: 20px;
  }
  button:hover{
    opacity: 0.7;
    background: none;
    border:none;
    width: 30px;
    height: 20px;
  }
}
.voteArrow{
  height:15px;
  width:15px;
}
#arrowLeft{
  transform: scaleY(-1);
}
#nodeViewMargin{
  margin: 10px;
}
