.leftMargin10{
  margin-left:10px;
}

.Page {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;

}

.pageGrid{
  margin:0;
  margin-left:10px;
  margin-right:10px;
  padding:0;

  height: (100vh-"10px");
}

#pageGridMargin{
  padding-left: 2.5vw;
}

.pageGridV{
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.pageGridH{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.commonButton{
  margin:5px;
  margin-top: 0px;
  width: 50px;
  border-radius: 2px;
  border: 1px solid $dark-grey;
  height:20px;
  color:black;
  background: $white;
  font-family: $secondary-font;
  font-size: 13px;
  text-align: center;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}

.commonButton:hover{
  margin:5px;
  margin-top: 0px;
  width: 50px;
  border-radius: 2px;
  border: 1px solid $dark-grey;
  height:20px;
  color:white;
  background: $dark-grey;
  font-family: $secondary-font;
  font-size: 13px;
  text-align: center;
}


#commonButtonLong{
  width: 90px;
}



.login{
  margin-top: 30px;
  border-radius: 2px;
  border: 1px solid $dark-grey;
  height:28px;
  color:black;
  background: white;
  padding-left:10px;
  padding-right:10px;
  font-family: $secondary-font;
  font-size: 18px;
  text-align: center;
  position:absolute;
  margin-left: 50px;
}

.login:hover{
  margin-top: 30px;
  border-radius: 2px;
  border: 1px solid $dark-grey;
  height:28px;
  color:white;
  background: $dark-grey;
  padding-left:10px;
  padding-right:10px;
  font-family: $secondary-font;
  font-size: 18px;
  text-align: center;
  position:absolute;
  margin-left: 50px;
}

.line {
    width: 1px;
    height: 100%;
    background: $soft-black;
    opacity: 0.1;
   z-index: 1;

}
.line-container {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: absolute;
    height: 100%;
    right: 2.5%;
    left: 2.5%;
    z-index: 1;
}

.doubleCheckModal{
  width: 150px;
  height:40px;
  text-align: center;
  position: absolute;
  z-index: 1010;

}

.display-linebreak {
  white-space: pre-line;
}

.toast-progress-bar{
  background: #f6bd10;
  height: 10px;
}
.toast-primary-background{
  background: #e7ae00;
}
.toast-font-size{
  color:white;
  font-size: 12px;
}
