@import '../base/settings';


.UploadNode{
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 905px;
  margin: 20px;
  margin-top: 30px;
  margin-left: calc(2vw - 10px);
  color: black;
}

.NodeBackground{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid gray;
  border-top: 5px solid $primary-color;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom:30px;
}


#title{
  width:460px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid lightgray;
  text-indent: 5px;
  font-family: $primary-font;
  font-size:13px;

}

#linkUpload{
  width:460px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid lightgray;
  text-indent: 5px;
  font-family: $primary-font;
  font-size:13px;
}

#linkUploadDesc{
  width:460px;
  min-height: 40px;
  max-height: 70px;
  border-radius: 3px;
  border: 1px solid lightgray;
  text-indent: 5px;
  font-family: $primary-font;
  font-size:13px;
}

#linkUploadSource{
  width:460px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid lightgray;
  text-indent: 5px;
  font-family: $primary-font;
  font-size:13px;
}

#linkUploadHeader{
  width:460px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid lightgray;
  text-indent: 5px;
  font-family: $primary-font;
  font-size:13px;
}

#desc{
  width: 460px;
  height: 130px;
  border-radius: 3px;
  border: 1px solid lightgray;
  text-indent: 5px;
  font-family: $primary-font;
  font-size:13px;

}
#refsource{
  width:460px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid lightgray;
  text-indent: 5px;
  font-family: $primary-font;
  font-size:13px;

}

.dropzone {
  background-color: rgb(235,235,235);
  width: 460px;
  height: 270px;
  position:relative;
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: -20px;

}

.uploadImage {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  z-index: -1;
}
.upload-icon {
  z-index: 0;
  position: absolute;
}

.cancelImageUpload{
  z-index: 5;
  background-color: #bf2a32;
  color:white;
  border-radius: 4px;
  border: 1px solid lightgray;
  height:23px;
  width:23px;
  position: relative;
  text-align: center;
  margin-left: 430px;
  margin-bottom: 242px;
}

.UploadToggle{
  width: 500px;
  height: 35px;
  float: left;
}

.ToggleButton {
  width: 200px;
  height: 35px;
  margin: auto;
  position: relative;
  text-align: center;
  display: inline-block;
  font-family: $primary-font;
  font-size: 16px;
  text-align: center;
  display:table-cell;
  vertical-align:middle;
  line-height: 20px;
  border: 1px solid gray;
  border-bottom: 0;

}

.editingHeader{
  width: 380px;
  height: 35px;
  padding-top: 7px;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  text-align: center;
  font-family: $primary-font;
  background-color: $primary-color;
  color:white;
  font-size: 16px;
  border: 1px solid gray;
  border-bottom:0;
}

.publishButton{
  margin-top: 8px;
  width: 227px;
  height: 30px;
  background: black;
  color: white;
  font-size: 16px;
  font-family: $primary-font;
  border-radius: 5px;
  border: none;



}

.editPublishButton{
  margin: 8px;
  margin-left:0;
  margin-right:0;
  width: 107px;
  height: 30px;
  background: black;
  color: white;
  position: relative;
  text-align: center;
  display: inline-block;
  font-family: $primary-font;
  font-size: 13px;
  text-align: center;
  display:table-cell;
  vertical-align:middle;
  border-radius: 5px;
  border: none;
  transition-duration: 0.3s;
}
.editPublishButton:hover{
  margin: 8px;
  margin-left:0;
  margin-right:0;
  width: 107px;
  height: 30px;
  background: $primary-color;
  color: white;
  position: relative;
  text-align: center;
  display: inline-block;
  font-family: $primary-font;
  font-size: 13px;
  text-align: center;
  display:table-cell;
  vertical-align:middle;
  border-radius: 5px;
  border: none;
  transition-duration: 0.3s;

}

.editCancelButton{
  margin: 8px;
  margin-left:0px;
  width: 111px;
  height: 30px;
  background: black;
  color: white;
  position: relative;
  text-align: center;
  display: inline-block;
  font-family: $primary-font;
  font-size: 13px;
  text-align: center;
  display:table-cell;
  vertical-align:middle;
  border: none;
  border-radius: 5px;
  transition-duration: 0.3s;

}
.editCancelButton:hover{
  margin: 8px;
  margin-left:0px;
  width: 111px;
  height: 30px;
  background: $primary-color;
  color: white;
  position: relative;
  text-align: center;
  display: inline-block;
  font-family: $primary-font;
  font-size: 13px;
  text-align: center;
  display:table-cell;
  vertical-align:middle;
  border: none;
  border-radius: 5px;
  transition-duration: 0.3s;

}


.publishButton:active{
  background: $dark-grey;
  border-radius: 5px;
  border: none;

}


.previewImage {
   height: auto;
   width: auto;
   max-width: 460px;
   max-height: 270px;
   z-index: 3;
   position: absolute;

}

#timelineUploadToggleContainer{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

#timelineUploadToggle{
  display: flex;
  flex-direction: column;
}

#contentNodeTypeContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
  background: none;
  justify-content: space-around;
  .contentNodeTypeToggleButton{
    height:50px;
    width: 50px;
    border-radius: 50%;
    background-color: $secondary-color;
    transition-duration: 0.3s;
    border: 1px solid lightgray;
    font-family: $primary-font;
  }
  .contentNodeTypeToggleButtonSelected{
    height:50px;
    width: 50px;
    border-radius: 50%;
    background-color: $primary-color;
    color:white;

    transition-duration: 0.3s;
    font-family: $primary-font;
    border: 1px solid lightgray;


  }
  .contentNodeTypeToggleButton:hover{
    height:50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid lightgray;

    background-color: $soft-black2;
    color:white;
    transition-duration: 0.3s;
    font-family: $primary-font;
  }
  .contentNodeTypeToggleButtonSelected:hover{
    height:50px;
    width: 50px;
    border-radius: 50%;
    color:white;
    background-color: $soft-black2;
    transition-duration: 0.3s;
    font-family: $primary-font;
    border: 1px solid lightgray;

  }
}

#currentUploadTimeline{
  margin-top:10px;
  margin-bottom: 15px;
  border-bottom: 1px solid lightgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 460px;
}

#uploadDropListContainer{
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  #uploadDropList{
    margin-top: 5px;
    margin-bottom:15px;
    width:460px;
  }
}

#nodeUploadToggleExplain{
  max-width: 130px;
  height: 85px;
  font-size: 12px;
  color: $medium-grey;
  transition-duration: 0.3s;
  margin-bottom: 5px;
}

#nodeUploadToggleExplain:hover{
  max-width: 130px;
  height: 85px;

  font-size: 12px;
  color: $soft-black2;
  margin-bottom: 5px;

}

#nodeUploadGeneralExplain{

  font-size: 12px;
  color: $medium-grey;
  transition-duration: 0.3s;
  margin-bottom: 5px;
  max-width: 460px;

}
#nodeUploadGeneralExplain:hover{
  max-width: 460px;
  font-size: 12px;
  color: $soft-black2;
  margin-bottom: 5px;

}

#linkUploadContainer{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

}

.linkUploadBanner{
  width: 460px;
  height: auto;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-bottom: 15px;

  img{
    width:460px;
    height: auto;
    background-size: contain;
    text-align: center;
    color:white;
  }
  .cancelImageUpload{
    z-index: 5;
    background-color: #bf2a32;
    color:white;
    border-radius: 4px;
    border: 1px solid lightgray;
    height:23px;
    width:23px;
    position: relative;
    text-align: center;
    margin-left: 430px;
    margin-bottom: 142px;
  }
  .upload-icon {
    z-index: 0;
    position: absolute;
    width: 20px;
    height: 20px;
  }
  overflow: hidden;
}
