

.ContentNode{
  display: flex;
  width:100vw;
  position:relative;

}


#uploadSection{
  height:calc(100vh - 40px);
  overflow: scroll;
  width: 630px;
  padding-right: calc(2vw + 30px);
  display: flex;
  flex-direction: row;
}

#uploadSectionCreate{
  display: flex;
  flex-direction: column;
}

#uploadSectionNavigation{
  height: 300px;
  width: 70px;
  margin-top: 91px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

#uploadSectionNavigationButton{
  height:100px;
  width:70px;
  background-color: $soft-black2;
  font-family: $primary-font;
  color:$soft-white;

  text-align: center;
  border: 1px solid $medium-grey;
  #buttonDetail{
    font-size: 12px;
    bottom: 15px;
    position: relative;
  }
}
#uploadSectionNavigationButtonSelected{
  height:100px;
  width:70px;
  background: $primary-color;
  font-family: $primary-font;
  color:$white;
  text-align: center;
  border: 1px solid $medium-grey;
  #buttonDetail{
    font-size: 12px;
    bottom: 15px;
    position: relative;
  }
}

.ContentNodeBackground{

  height: 100vh;
  width: 100%;
  overflow: scroll;
  position: relative;
}
//  overflow: scroll;

#uploadNodePageSection{
  height:calc(100vh - 40px);
  overflow: scroll;
  width: 630px;
  padding-right: 2vw;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}
