#commentSection{
  width: 100%;
  // background-color: #F8F8F8;
  background-color: white;
  border-top: 4px solid $soft-black;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

#content{
  border: 1px solid gray;
  border-radius: 15px;
  width:300px;
  height: 30px;
  outline: none;
  text-indent: 5px;
}

.submitButton{
  display:inline-block;
  border-radius: 3px;
  border: none;
  width:120px;
  height: 30px;
  position:relative;
  margin-left: 10px;
  outline:none;
  font-size: 12px;
  padding: 3px;
  color:$soft-white;
  font-family: $primary-font;
  background-color: $soft-black2;
  transition-duration: .2s;
}

.submitButton:hover{
  display:inline-block;
  border-radius: 3px;
  border: 1px solid lightgray;
  width:120px;
  height: 30px;
  position:relative;
  margin-left: 10px;
  outline:none;
  font-size: 12px;
  padding: 3px;
  color:white;
  font-family: $primary-font;
  background-color: $dark-grey;
  transition-duration: .2s;
}


#nestedCommentContainer{

  margin-left: 30px;
}

#commentContainer{
  margin: 10px;
}

#commentContent{
  background-color: white;
  min-height: 40px;
  border-radius: 15px;
  padding: 10px;
  box-sizing: border-box;
  display:inline-flex;
  font-size: 14px;
  #commentText{
    word-wrap: break-word;
    word-break: break-word;
    font-size: inherit;

  }
}

.icon{
  width: auto;
  height: 15px;
  margin: 10px;
}

#editDeleteContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  height:30px;
}

#editDeleteButtonContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  height:60px;
  border: 1px solid lightgray;
  border-top: 2px solid $primary-color;
}

.editDeleteButton{
  background-color: white;
  width:50px;
  outline:none;
  border:none;
  font-family: inherit;

}

.editDeleteButton:hover{
  background-color: white;
  width:50px;
  outline:none;
  border:none;
  text-decoration: underline;
  font-family: inherit;

}

#commentContentButtonContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}

#commentContentInfoContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
  width:100%;
}


#commentContentContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}

#expandRepliesButton{
  background: none;
  border:none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 25px;
  outline: none;
  font-family: inherit;

}

#expandRepliesButton:hover{
  background: none;
  border:none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 25px;
  text-decoration: underline;
  outline: none;
  font-family: inherit;

}

#replyButton{
  background: none;
  border:none;
  margin-left:10px;
  display: flex;
  align-items: center;
  outline: none;
  font-family: inherit;


}

#replyButton:hover{
  background: none;
  border:none;
  margin-left:10px;
  display: flex;
  align-items: center;
  text-decoration: underline;
  outline: none;
  font-family: inherit;


}

#discussionHeader{
  display:flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid gray;
}

#commentDate{
  float:right;
  font-size: 10px;

}

#mainSubmit{
  border-top: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  box-sizing: content-box;


}

#username{
  color: $primary-color;
  margin-right: 8px;
}
#username:hover{
  color: $primary-color;
  margin-right: 8px;
  text-decoration:underline;
}
