.QueryNodeViewContainer{
  background-color: $soft-black2;
  width:610px;
  height:350px;
  margin-bottom: 10px;
}

.lineQueryNodeView {
    width: 1px;
    height: 100%;
    background: $white;
    opacity: 0.08;
    z-index: 10;

}
.lineQueryNodeViewDark{
    width: 1px;
    height: 100%;
    background: $soft-black2;
    opacity: 0.08;
    z-index: 10;
}
.line-containerQueryNodeView {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: absolute;
    height: inherit;
    right: 12%;
    left: 12%;
    z-index: 10;
}

.contentQueryNodeView{
  display: flex;
  flex-direction: column;
  position: absolute;
  height: inherit;
  color: $soft-white;
  z-index: 10;
  font-size: 12px;

  margin-left: 10px;
}


#queryNodeViewHeaderContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;

  #queryNodeViewHeader{
    font-size: 75px;
  }
  #queryNodeViewHeaderDetail{
    font-size: 15px;
    top: -30px;
    right: 10px;
    position: relative;
    font-weight: 0;
    font-family: inherit;

  }
}

#profileNodeViewHeaderContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;

  #profileNodeViewHeader{
    font-size: 50px;
  }
  #profileNodeViewHeaderDetail{
    font-size: 15px;
    top: -30px;
    right: 10px;
    position: relative;
    font-weight: 0;
    font-family: inherit;

  }
}
#profileNodeViewInfoContainer{
  font-weight: 0;
  font-family: inherit;
  display: flex;
  flex-direction: row;
}

#profileNodeViewInfoContent{
  display: flex;
  flex-direction: Column;
}

#profileNodeViewInfoImage{
  margin-left: 50px;
  margin-right: 20px;
  height: 160px;
  width: 160px;
  img{
    height:inherit;
    width: inherit;
  }
}
#profileSectionNavigation{
  height: 300px;
  width: 70px;
  margin-top: 20px;
  margin-right: 50px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;

}

#profileNavigationButton{
  height:60px;
  width:70px;
  background-color: $soft-black2;
  font-family: $primary-font;
  color:$soft-white;

  text-align: center;
  border: 1px solid $medium-grey;
  #buttonDetail{
    font-size: 12px;
    bottom: 5px;
    position: relative;
  }
}
#profileNavigationButtonSelected{
  height:60px;
  width:70px;
  background: $primary-color;
  font-family: $primary-font;
  color:$white;
  text-align: center;
  border: 1px solid $medium-grey;
  #buttonDetail{
    font-size: 12px;
    bottom: 5px;
    position: relative;
  }
}

.profileQueryNodeView{
  display: flex;
  flex-direction: row;
  height: inherit;
  color: $soft-white;
  z-index: 10;
  font-size: 12px;
  .contentQueryNodeView{
    display: flex;
    flex-direction: column;
    height: inherit;
    color: $soft-white;
    z-index: 10;
    font-size: 12px;
    margin-left: 100px;
  }

}


.feedNodeViewHeader{
  margin-top: 100px;
}

.profileNodeViewHeader{
  margin-top: 30px;
}

#feedNodeViewInfoContainer{
  font-weight: 0;
  font-family: inherit;
  display: flex;
  flex-direction: row;
}

#feedNodeViewInfoContent{
  display: flex;
  flex-direction: Column;
  width: 200px;
  margin-left: 50px;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 0;

  #headerText{
    word-wrap: break-word;
    word-break: break-word;
    font-size: inherit;
  }
}

#feedQueryNodeView{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#feedNodeViewImageContainer{
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 280px;
  color:$soft-white;

  img{
    height:50px;
    width: auto;
  }
  #FeedRectOne{
    margin-bottom: 15px;
    transform: scaleX(-1);

  }
  #FeedRectTwo{
    position:relative;
    margin-bottom: 15px;
    left: 120px;
  }
  #FeedRectThree{
    position:relative;
    margin-bottom: 15px;
    left: 55px;
    transform: scaleY(-1);

  }
  #text{
    margin-bottom: 10px;
    left: 55px;
    position: relative;
    font-weight: 0;
    font-size: 12px;

  }
}
