@import '../base/settings';

.navBar {
  list-style: none;
  color: $primary-color;
  margin-top: 16px;
  margin-left: 50px;
  font-size: 11.5px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navBarCenter{
  margin-top: -16px;
  margin-left: 2vw;
  height:45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:45vw;
}

img[alt="logo-icon"]{
  width:40px;
  height:40px;
}
.navComp {
  display: flex;

}

.navLogo {
  display: flex;
  flex-direction: row;
  color:$soft-white;
  font-size: 0px;
  margin-top: -16px;
  div{
    margin-top: 10px;
    margin-left: 4px;
  }
}

.pageNav{
  width: 100vw;
  height: 40px;
  background: $soft-black;
  overflow: hidden;
}

.navBarItem{
  display: block;
  position: relative;
  z-index: 2;
  text-decoration: none;
  box-sizing: border-box;
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 0;
  height:45px;
}

#navBarItemUnAuth{

  padding: 10px;
  padding-bottom: 20px;
  color: $medium-grey;
  text-decoration: none;
  font-size: 14px;
  font-family: $primary-font;
  background: none;
  border:none;
}


// .navBarItem:after{
//   content: "";
//   height: 10%;
//   left: 0;
//   top: 0;
//   right:0;
//   width: 0px;
//   position: absolute;
//   transition: all 0.5s ease 0s;
//  -webkit-transition: all 0.5s ease 0s;
//   z-index: -1;
//   background-color: white;
//     // content: "hi";
//     // position: absolute;
//     // top: 0;
//     // left: 0;
//     // right: 0;
//     // height: 10px;
//     // background-color: white;
//     // -webkit-transform-origin: center top;
//     // -ms-transform-origin: center top;
//     // transform-origin: center top;
//     // -webkit-transform: scaleY(0);
//     // -ms-transform: scaleY(0);
//     // transform: scaleY(0);
//     // -webkit-transition: -webkit-transform .3s cubic-bezier(.215,.61,.355,1);
//     // transition: -webkit-transform .3s cubic-bezier(.215,.61,.355,1);
//     // transition: transform .3s cubic-bezier(.215,.61,.355,1);
//     // transition: transform .3s cubic-bezier(.215,.61,.355,1),-webkit-transform .3s cubic-bezier(.215,.61,.355,1);
// }
// .navBarItem:hover:after{
//    width: 100%;
// }

.signUpOutButton{
  margin-top: -11px;
  margin-right:20px;
  width: 80px;
  border-radius: 30px;
  height:30px;
  color:white;
  border: none;
  background: $dark-grey;
  font-family: $primary-font;
  font-size: 12px;
  text-align: center;
  transition-duration: 0.3s;
}

.signUpOutButton:hover{
  margin-top: -11px;
  margin-right:20px;
  width: 80px;
  border-radius: 30px;
  height:30px;
  color:white;
  border: none;
  background: $primary-color;
  font-family: $primary-font;
  font-size: 12px;
  text-align: center;
  transition-duration: 0.3s;

}
#signInButton{
  margin-top: -11px;
  margin-right:20px;
  width: 80px;
  border-radius: 30px;
  height:30px;
  padding-top: 8px;
  box-sizing: border-box;
    color:white;
  font-family: $primary-font;
  font-size: 13px;
  text-align: center;
}

#navBarFeedback{
  margin-top: -15px;

  padding: 10px;
  padding-bottom: 20px;
  color: $medium-grey;
  text-decoration: none;
  font-size: 14px;
  font-family: $primary-font;
  background: none;
  border:none;
  transition-duration: 0.5s;

}

#navBarFeedback:hover{
  margin-top: -15px;

  padding: 10px;
  padding-bottom: 20px;
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-family: $primary-font;
  background: none;
  border:none;
}

#navBarRight{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:240px;
}
#atlasVersion{
  font-size: 11px;
  color: $soft-white;
  position:relative;
  top: -15px;
  display: flex;
  flex-direction: row;
  #atlasVersionNumber{
    font-size: 11px;
    position:relative;
    top: -10px;
    left:-3px;
    color: #f7be10;
  }
}

#atlasLogoText{
  display:flex;
  flex-direction: row;
}
