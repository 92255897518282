.atlasDescription{
  width: 240px;
  position:relative;
  color: $primary-color;
  font-size: 14px;
  top: 200px;
  margin-left:50px;
}


.loginModal {
    z-index:1010;
    position: absolute;
    width: 70vw; /* Full width */
    height: 70vh; /* Full height */
    background: black;
    top: 15vh;
    left:30vh;
    display: flex;
    flex-direction: row;

}




/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
