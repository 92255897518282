#landingCreateNodeView{
  background-color: $soft-black2;
  width:610px;
  height:420px;
  margin-bottom: 10px;
  color:white;
}

#landingCreateNodeView{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}


#landingCreateNodeView{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#landingCreateNodeViewHeaderContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
  margin-top: 70px;
  color:$soft-white;

  #landingCreateNodeViewHeader{
    font-size: 30px;
  }
  #landingCreateNodeViewHeaderDetail{
    font-size: 12px;
    top: -20px;
    right: 10px;
    position: relative;
    font-weight: 0;
    font-family: inherit;

  }
}

#landingCreateNodeViewInfoContainer{
  font-weight: 0;
  font-family: inherit;
  display: flex;
  flex-direction: row;
}

#landingCreateNodeViewInfoContent{
  display: flex;
  flex-direction: column;
  width: 330px;
  margin-left: 30px;
  margin-top: 15px;
  font-weight: 0;
  color:$soft-white;
  #headerText{
    word-wrap: break-word;
    word-break: break-word;
    font-size: inherit;
    font-size: 14px;
    margin:10px;

  }
}

#landingCreateNodeViewImageContainer{
  display: flex;
  flex-direction: row;
  margin-top: 230px;
  margin-left: 330px;
  color:$soft-white;

  img{
    height:50px;
    width: auto;
    -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
  }
  #FeedRectOne{
    margin-bottom: 15px;
    height:100px;
    width: 50px;
    margin-right: 15px;
  }
  #FeedRectTwo{
    position:relative;
    margin-bottom: 15px;
    width: 50px;
    bottom: 120px;
    margin-right: 15px;


  }
  #FeedRectThree{
    position:relative;
    margin-bottom: 15px;
    bottom: 50px;
    width: 50px;
    margin-right: 15px;
  }
}
