// Colors fafafa
// $secondary-color: #faf7f4;
// faf7f4
// $secondary-color: #fafafa;
// $primary-color: #5e779c;

$dark-grey: #363636;
$light-grey: #ececec;
$medium-grey: #a4a4a4;
// $primary-color: #0976f2;
// $primary-color:#0f70dd;  f6bd10;
$primary-color:#e7ae00;
$primary-color-complement:#f6bd10;
$secondary-color: #f9f9f9;

// faf7f4;

$white: #fff;
$soft-white: #e9e9e9;

$soft-black:#191919;
$soft-black2:#232323;

$soft-green: #dee0d0;
$desc-gray: rgba(0,0,0,.54);

@font-face {
    font-family: futureBook;
    src: url(/public/Futura-Medium.woff) ;
}

$primary-font: futureBook;
$secondary-font: 'times new roman';

// $primary-color: rgba(231, 174, 0, 1);
// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;


.sm{
  font-size: 12px;
}

.primaryColor{
  color:$primary-color;
}

#primaryBackground{
  background:$primary-color;
  color: white;
}

#whiteBackground{
  background: white;
  color: black;

}
