@import './settings';

* {
  margin: 0;
  padding: 0;
}

.active {
  color: white;
}

a {
  color: $medium-grey
}

a:link    {
  color: $medium-grey;
  text-decoration: none;
  font-size: 14px;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;

}
a:hover    {
  color: white;
  text-decoration: none;
  font-size: 14px;

}


div {
  font-family: $primary-font;
  font-size: 16px;
}

p {
   margin-top: 5px;
   margin-bottom:5px;
}

textarea {
   resize: none;
}

input {
  border: 1px solid gray;
}

html {background-color: $secondary-color}

input[type="text"] {
    width: 228px;
}

textarea {
    width: 220px;
    height:282px;
}


*:focus {
    outline: none;
}


#app{
  position: absolute;
  z-index: 2;
  padding:0;
  margin:0;
}

html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow:hidden;

}

body {
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  background-color: $secondary-color;

}

::-webkit-scrollbar-track {
    display:none;
}
