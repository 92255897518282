
.uploadDirectoryBox{
  display: flex;
  flex-direction: column;
  margin-top:40px;
  width: 500px;
  height: 2500px;
  color: black;

}

.uploadDirectoryBackground{
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid gray;
  margin:10px;
  margin-top: 0px;
  height: 335px;
  width: 500px;
  overflow: scroll;
  padding-bottom: 50px;
  margin-left: calc(2vw - 10px);
}

.uploadDirectoryHeader{
  margin:10px;
  color: black;
  background: white;
  border: 1px solid gray;
  height: 35px;
  width: 500px;
  z-index: 3;
  position:relative;
  margin-left: calc(2vw - 10px);

  text-align: center;
}

.nodeUploadDirectoryTimelineSelect{
  margin-bottom:6px;
  margin-top: 6px;
  border: 1px solid lightgray;
  margin-right: 5px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  height:25px;
  background: $secondary-color;
  font-family: $primary-font;
  font-size: 13px;
}

.nodeUploadDirectoryTimelineSelect:hover{
  margin-bottom:6px;
  margin-top: 6px;
  margin-right: 5px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  height:25px;
  background: $dark-grey;
  font-family: $primary-font;
  font-size: 13px;
  border: 1px solid  $soft-black;
  color: white;
}

.nodeUploadDirectoryTimelineSelected{
  margin-top:6px;
  margin-bottom: 6px;
  margin-right: 5px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  height:25px;
  color:white;
  font-family: $primary-font;
  font-size: 13px;

  background: $soft-black;
  border: 1px solid  $soft-black;
}

.nodeUploadDirectoryTimelineSelected:hover{
  margin-top:6px;
  margin-top: 6px;

  margin-right: 5px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;

  height:25px;
  font-family: $primary-font;
  font-size: 13px;
  background: $dark-grey;
  color: white;
}

#masterTNodeChildrenContainer{
  margin-left: 20px;
  margin-top:20px;
}

.nodeDirectoryView{
  margin-right: 5px;
  margin-left: 3px;

  opacity: 0.1;
  background: none;
  border: 0;
  transition-duration: 0.3s;

  img{
    width:20px;
    height:20px;
  }
}

.nodeDirectoryView:hover{
  margin-right: 5px;
  margin-left: 3px;

  opacity: 0.3;
  background: none;
  border: 0;
  img{
    width:20px;
    height:20px;
  }
}

.nodeDirectoryViewSelected
{
  opacity: 1;
  background: none;
  border: 0;
  margin-right: 5px;
  margin-left: 3px;
  transition-duration: 0.3s;
  img{
    width:20px;
    height:20px;
  }
}

.nodeDirectoryViewSelected:hover
{
  opacity: 0.8;
  background: none;
  border: 0;
  margin-right: 5px;
  margin-left: 3px;

  img{
    width:20px;
    height:20px;
  }
}


#nodeDirectoryTimelimeTitle{
  font-size: 12.5px;
  border-bottom: 1px solid lightgray;
  width: 100%;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  #lockedTimelineIcon{
    width:15px;
    height:15px;
    position: relative;
    margin-bottom: 3px;
    margin-right: 3px;
    transition-duration: 0.3s;
  }
}

#nodeDirectoryTimelimeButtonContainer{
  display:flex;
  flex-direction: row;
  align-items: center;
}

#masterDirectoryTimelimeButtonContainer{
  display:flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
#masterDirectoryTimelimeButtonAlternateContainer{
  display:flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-left: 5px;
}

#nodeDirectoryHeaderExplain{
  font-size: 12px;
  color: $medium-grey;
  margin-top: 25px;
  margin-left: 5px;
  transition-duration: 0.3s;
}
#nodeDirectoryHeaderExplain:hover{
  font-size: 12px;
  color: $soft-black2;
}

#nodeDirectoryExplain{
  font-size: 12px;
  color: $medium-grey;
  transition-duration: 0.3s;
}

#nodeDirectoryExplain:hover{
  font-size: 12px;
  color: $soft-black2;
}

#nodeDirectoryNoTimelinesText{
  font-size: 12px;
  color: $primary-color;
  margin-left: 25px;
}
