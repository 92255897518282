#landingStoryNodeView{
  background-color: $soft-green;
  width:610px;
  height:420px;
  margin-bottom: 10px;
}

#landingStoryNodeView{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#landingStoryNodeViewHeaderContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
  color:white;

  #landingStoryNodeViewHeader{
    color:$soft-black2;
    font-size: 55px;
  }
  #landingStoryNodeViewHeaderDetail{
    font-size: 12px;
    top: -35px;
    right: 10px;
    position: relative;
    font-weight: 0;
    font-family: inherit;

  }
}

#landingStoryNodeViewInfoContainer{
  font-weight: 0;
  font-family: inherit;
  display: flex;
  flex-direction: row;
}

#landingStoryNodeViewInfoContent{
  display: flex;
  flex-direction: column;
  width: 320px;
  margin-left: 50px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 0;
  color:white;
  #headerText{
    word-wrap: break-word;
    word-break: break-word;
    font-size: inherit;
    font-size: 13px;

  }
  #headerTextBlack{
    word-wrap: break-word;
    word-break: break-word;
    font-size: inherit;
    color:black;
    margin-top:20px;
  }
}

#landingStoryNodeViewImageContainer{
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 300px;
  color:$soft-white;


  #FeedRectOne{
    margin-bottom: 15px;
    transform: scaleX(-1);
    height:100px;
    width: 100px;
    img{
      height:120px;
      width: 120px;
    }
  }
  #FeedRectTwo{
    position:relative;
    margin-bottom: 15px;
    left: 180px;
    bottom: 50px;

    img{
      height:70px;
      width: 70px;
    }
  }
  #FeedRectThree{
    position:relative;
    margin-bottom: 15px;
    left: 160px;
    bottom: 30px;

    transform: scaleY(-1);
    img{
      height:40px;
      width: 40px;
    }

  }
}
