#loginInputContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.signInput{
  width: 250px;
  color:$soft-white;
  margin:5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  input{
    background-color:$soft-white;
    border:none;
    border-radius: 7px;
    height: 20px;
    width:160px;
    outline: none;
    text-indent: 8px;
    font-size: 15px;
    font-family: $primary-font;
  }
}

.signContainer{
  margin: 20px;
  height:100px;
}

#signUpInButton{
  background-color:$primary-color;
  color: $white;
  border-radius: 30px;
  font-size: 12px;
  border: none;
  font-family: inherit;
  padding:7px;
  margin-top: 10px;
  margin-left: 40px;
  align-self:center;

}

.SignForm {
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  width:300px;
  margin-top: 15px;
  height:120px;
}

#toggleSignUpInButton{
  background: none;
  border: none;
  top: -30px;
  left: 30px;
  font-size: 12px;
  font-family: $primary-font;
  position: relative;
  color: $soft-white;

}
#toggleSignUpInButton:hover{
  background: none;
  border: none;
  top: -30px;
  left: 30px;
  font-family: $primary-font;

  font-size: 12px;
  position: relative;
  color: $soft-white;
  text-decoration: underline;
}

#errorMessageContainer{
  position: absolute;
  z-index: 10;
  margin-left: 50px;
  margin-top: 125px;
  font-size: 11px;
  color:#ff0065;
  div{
    font-size: 11px;
    color:#ff0065;
  }
}

.passwordShowToggle{
  display: none;
  z-index: 5;
  font-family: $primary-font;
  position: absolute;
  bottom:122px;
  left: 260px;
  transition-duration: 0.3s;
}
