#landingPermissionsNodeView{
  background-color: $soft-black2;
  width:610px;
  height:420px;
  margin-bottom: 10px;
  color:white;
}

#landingPermissionsNodeView{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}


#landingPermissionsNodeView{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#landingPermissionsNodeViewHeaderContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
  margin-top: 100px;
  color:$soft-white;

  #landingPermissionsNodeViewHeader{
    font-size: 30px;
  }
  #landingPermissionsNodeViewHeaderDetail{
    font-size: 12px;
    top: -20px;
    right: 10px;
    position: relative;
    font-weight: 0;
    font-family: inherit;

  }
}

#landingPermissionsNodeViewInfoContainer{
  font-weight: 0;
  font-family: inherit;
  display: flex;
  flex-direction: row;
}

#landingPermissionsNodeViewInfoContent{
  display: flex;
  flex-direction: column;
  width: 320px;
  margin-left: 40px;
  margin-top: 20px;
  font-size: 17px;
  font-weight: 0;
  color:$soft-white;
  #headerText{
    word-wrap: break-word;
    word-break: break-word;
    font-size: inherit;
    font-size: 14px;
    margin:10px;

  }
}

#landingPermissionsNodeViewImageContainer{
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-left: 390px;
  color:$soft-white;

  img{
    height:350px;
    width: 150px;

  }
  #FeedRectOne{
    margin-bottom: 15px;
    margin-right: 15px;
  }

}
