.landingNodeViewContainer{
  background-color: $soft-black2;
  width:610px;
  height:420px;
  margin-bottom: 10px;
}


#landingLoginNodeView{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#landingLoginNodeViewHeaderContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;


  #landingLoginNodeViewHeader{
    margin-top:10px;
    font-size: 55px;
  }
  #landingLoginNodeViewHeaderDetail{
    margin-top:10px;

    font-size: 12px;
    top: -35px;
    right: 10px;
    position: relative;
    font-weight: 0;
    font-family: inherit;

  }
}

#landingLoginNodeViewInfoContainer{
  font-weight: 0;
  font-family: inherit;
  display: flex;
  flex-direction: row;
}

#landingLoginNodeViewInfoContent{
  display: flex;
  flex-direction: Column;
  width: 400px;
  margin-left: 50px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 0;

  #headerText{
    word-wrap: break-word;
    word-break: break-word;
    font-size: inherit;
  }
}

#landingLoggedInSpace{
  height:100px;
}
