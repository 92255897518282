@import '../base/settings';

.timelineViewBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:500px;
  height: calc(100vh - 40px);
  position: relative;
  color: black;
  margin-left: 1.5vw;
  margin-right:1.5vw;
}


.timelineTitleQuery {
  font-size: 20px;
  max-width: 370px;
  width:auto;
  p{
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Times New Roman";
  font-weight: 100;
  margin-top: 15px;
  text-align: center;
  z-index:1010;
  position:relative;
  border:none;
  background-color: $secondary-color;
}

.timelineTitle{
  font-size: 20px;
  max-width: 370px;
  width:auto;
  p{
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color:black;
  }

  padding-left: 10px;
  padding-right: 10px;
  font-family: "Times New Roman";
  font-weight: 100;
  margin-top: 15px;
  text-align: center;
  z-index:1010;
  position:relative;
  border:none;
  border-bottom:1px solid lightgray;
  transition-duration: 0.3s;
  background-color: $secondary-color;
}

.timelineTitle:hover {
  font-size: 20px;
  max-width: 370px;
  width:auto;
  p{
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Times New Roman";
  font-weight: 100;
  margin-top: 15px;
  text-align: center;
  z-index:1010;
  position:relative;
  border:none;
  border-bottom:1px solid $dark-grey;
  transition-duration: 0.3s;
  background-color: $secondary-color;
}

.timelineHeader{
  width:400px;
  height:50px;
  position:absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index:1010;
}

.timelineHeaderLine{
  width:450px;
  height:0;
  border-bottom: 1px solid $medium-grey;
  position:absolute;
  bottom:40%;
  z-index:1000;
}




.timelineDescriptionContent{
  font-size: 13px;
  padding-bottom: 40px;
  overflow: scroll;
  height:65px;
  padding-left: 33px;
  padding-right: 33px;

}

.timelineDescription {
  position: absolute;
  text-align: center;
  bottom:25px;
  width:430px;
  height:125px;
}

.timelineDescriptionHeader{
  text-align:center;
  font-size: 19px;
  font-family: "Times New Roman";
  border-bottom: 1px solid $dark-grey;

}

.uploadTimelineViewbox{
  display: flex;
  flex-direction: column;
  align-items: center;
  width:500px;
  height: calc(100vh - 45px);
  position: relative;
  color: black;
  //
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // width:560px;
  // height: calc(100vh - 60px);
  // position: relative;
  // color: black;
  // overflow: scroll;
  // height: 900px;
  // position: relative;
  // margin:10px;
  // margin-left: 5px;
  // box-sizing: border-box;
}






.triangleDown {
  width: 0;
  height: 0;
  z-index:1100;
  position:absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 12px solid $soft-black;
}

.triangleUp {
  margin-top:10px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 6px solid $secondary-color;
  position: absolute;
  z-index:1100;
  bottom: -10px;
}

.timelineLineFooterRectangle{
  margin-top:10px;
  bottom: -10px;
  height:12px;
  width: 350px;
  background: black;
  position: absolute;
  z-index: 2;
}

.timelineFooterContainer{
  position:absolute;
  justify-content: center;
  display: flex;
  bottom:12px;
}

.commonButtonTimelineContainer{
  position:absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height:100px;
  width: 80px;
  background: $soft-black;
  top: 10px;
  right:-65px;
  z-index: 1200;
}

.timelineInfoContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  top: 50px;
  left:50px;
  border-radius: 2px;
  border: 1px solid $dark-grey;
  font-family: $secondary-font;
  font-size: 13px;
  z-index:10;
  position:absolute;
}

.nodeDate{
  font-size: 15px;
}

.nodeAuthor{
  font-size: 15px;
}

.timelineRoot{
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  top:55px;
}

.timelineRootNodeConnectorLine{
   border: none;
   border-top: 1px dotted black;
}
.leftTimelineRootNodeConnectorCircle{
  border: 2px solid $secondary-color;
  background:black;
  width:4px;
  height:4px;
  border-radius: 50%;
  left: 114.7%;
  position: relative;
  top:-125px;
}

.leftTimelineRootNodeConnectorDotLine{
  border-bottom: 1px dotted $medium-grey;
  width:14px;
  left: 108%;
  top:-130px;
  position: relative;

}

.leftTimelineRootNodeConnectorDotLine2{
  border-right: 1px dotted $medium-grey;
  border-bottom: 1px dotted $medium-grey;
  width:14px;
  height:50px;
  left: 100.5%;
  position: relative;
  top:-130px;

}



.rightTimelineRootNodeConnectorCircle{
  border: 2px solid $secondary-color;
  background: black;
  width:4px;
  height:4px;
  border-radius: 50%;
  left: -18.8%;
  position: relative;
  top:-115px;

}

.rightTimelineRootNodeConnectorDotLine{
  border-bottom: 1px dotted $medium-grey;
  width:14px;
  left: -15%;
  position: relative;
  top:-120px;

}

.rightTimelineRootNodeConnectorDotLine2{
  border-bottom: 1px dotted $medium-grey;
  border-left: 1px dotted $medium-grey;
  width:14px;
  height:50px;
  left: -8%;
  z-index: 4;
  position: relative;
  top:-119px;

}


.timelineLineUpload{
  height: 83.5vh;
  width: 1px;
  background: lightgray;

  // height:680px;
  // width: 1px;
  // background: lightgray;
}

.timelineLine{
  height: 83.5vh;
  width: 1px;
  background: lightgray;

}
.timelineLineRectangle{
  height: 2px;
  width: 9px;
  background: black;
}



.timelineNodePreview{
  max-width: 185px;
  height: auto;
  max-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-bottom: 5px;
  transition-duration: 0.5s;

  img{
    width:auto;
    height: 160px;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    background-image: url('/public/images/imageDefault.jpg');
    background-size: contain;
    text-align: center;
    color:white;
  }
  overflow: hidden;
}

.timelineNodePreviewButton{
  position:relative;
  z-index:100;
  border:none;
  text-align: left;
  .nodeContainerContent{
    min-height:225px;
    width: 185px;
    border: 1px solid $light-grey;
    background-color: white;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }

}

.isDisabled {
  pointer-events: none;
  cursor: default;
}

#previewButton{
  position: relative;
  display:block;
  z-index:4;
  margin-bottom:-50px;
  border: none;

}
#triButtonShareIcon{
  width: 12px;
  height:12px;
}

#previewButton:hover, #previewButtonSelected{
  position: relative;
  z-index:4;
  display:block;

  margin-bottom:-50px;
  .nodeContainerTimeline{

    .nodeContainerTimelineContainer{
      background-color: $light-grey;
      img{
        -webkit-transform:scale(1.1);
        -moz-transform:scale(1.1);
        -ms-transform:scale(1.1);
        -o-transform:scale(1.1);
        transform:scale(1.1);
      }
    }
    .nodeContainerBackground1{
      background-color: white;
      img{
        -webkit-transform:scale(1.1);
        -moz-transform:scale(1.1);
        -ms-transform:scale(1.1);
        -o-transform:scale(1.1);
        transform:scale(1.1);
      }
    }
  }
  .nodeContainerContent{
    background-color: $light-grey;
    img{
      -webkit-transform:scale(1.1);
      -moz-transform:scale(1.1);
      -ms-transform:scale(1.1);
      -o-transform:scale(1.1);
      transform:scale(1.1);
    }
  }
  .timelineNodePreview{
    background-color: $light-grey;
  }
}

#previewButtonNewNode{
  position: relative;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  z-index:4;
  margin-bottom:-50px;
  height: 225px;
  width: 185px;
  border: 1px solid #ececec;
  background-color: white;
  .newNodeContainerButton{
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    text-align: center;

    height: 80px;
    margin:20px;
    background-color: $soft-black2;
    color: $white;
    border: 1px solid $soft-black2;
    border-radius: 5px;
    font-family: $primary-font;
    font-size: 13px;
    transition-duration: 0.3s;
  }
  .newNodeContainerButton:hover{
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    text-align: center;

    height: 80px;
    margin:20px;
    background-color: $primary-color;
    color: $white;
    border: 1px solid $primary-color;
    border-radius: 5px;
    font-size: 13px;
    font-family: $primary-font;

  }
  #masterSelected{
    display: flex;
    flex-direction:row;
    justify-content: center;
    text-align: center;
    margin:20px;
    background-color: $soft-black2;
    color: $white;
    border: 1px solid $soft-black2;
    border-radius: 5px;
    font-family: $primary-font;
    font-size: 13px;
    transition-duration: 0.3s;
    height: 160px;
  }
  #masterSelected:hover{
    display: flex;
    flex-direction:row;
    justify-content: center;
    text-align: center;
    height: 160px;
    margin:20px;
    background-color: $primary-color;
    color: $white;
    border: 1px solid $primary-color;
    border-radius: 5px;
    font-size: 13px;
    font-family: $primary-font;
  }
}

#rightTokenCount{
  position: absolute;
  left: 10px;
  bottom:13px;
  z-index: 5;
  font-size: 10px;
}
#leftTokenCount{
  position: absolute;
  left:45px;
  bottom:13px;
  z-index: 5;
  font-size: 10px;

}

#rightPreviewDate{
  position: absolute;
  right: -30px;
  z-index:5;

}

#leftPreviewDate{
  position: absolute;
  left: -30px;
  z-index:5;

}

#rightDisplayNodeDeleteButton{
  margin-top: 2px;
  position: absolute;
  right: 40px;
  z-index:5;
  pointer-events:auto;
  cursor: default;
}

#leftDisplayNodeDeleteButton{
  margin-top: 2px;
  position: absolute;
  left: 40px;
  z-index:5;
  pointer-events:auto;
  cursor: default;

}

#leftDisplayNodeEditButton{
  margin-top: -18px;
  position: absolute;
  left: 100px;
  z-index:5;
  pointer-events:auto;
  cursor: default;
}

#rightDisplayNodeEditButton{
  margin-top: -18px;
  position: absolute;
  right: 100px;
  z-index:5;
  pointer-events:auto;
  cursor: default;

}

#rightHiddenNodeDeleteButton{
  display: none;
}
#rightHiddenNodeEditButton{
  display: none;
}
#leftHiddenNodeEditButton{
  display: none;
}

#leftHiddenNodeDeleteButton{
  display: none;
}



.nodeContainerTimeline{
  position:relative;
  .nodeContainerTimelineContainer{
    position: relative;

    z-index:5;
    height:225px;
    width: 185px;
    border: 1px solid $light-grey;
    background-color: white;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
  .nodeContainerBackground1{
    position: absolute;
    right: 4px;
    bottom: 3px;
    z-index: 3;
    height:225px;
    width: 185px;
    border: 1px solid $light-grey;
    background-color: #f9f9f9;
    transition-duration: 0.5s;

    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;

  }
  .nodeContainerBackground2{
    position: absolute;
    bottom:6px;
    right:8px;
    z-index: 2;
    height:225px;
    width: 185px;
    border: 1px solid $light-grey;
    background-color: white;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
}


.nodeContainerInfo{
  margin: 10px;
  margin-left: 10px;
  margin-right: 20px;
  font-size: 9.5px;
}

.nodeContainerTitle{
  margin: 3px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top:-8px;
  display: flex;
  font-size: 11.5px;
  line-height: 1;
  flex-direction: row;
  align-items: center;
  width: 250px;
  min-height:20px;
  max-height: 30px;

/* https://codepen.io/martinwolf/pen/qlFdp */
  $font-size: 11.5px;
  $line-height: 1.4;
  $lines-to-show: 2;
div {
  display: block;
  display: -webkit-box;
  max-width: 165px;
  max-height: $font-size*$line-height*$lines-to-show;
  margin: 0;
  margin-left:-10px;
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
  #lockedTimelineIcon{
    width:15px;
    height:15px;
    left: -17px;
    position: relative;
    margin-right: 3px;
    transition-duration: 0.3s;
  }
}

.nodeContainerDescription{

}


.tNodeDate{
  width: 65px;
  background-color: $soft-black;
  font-size: 10px;
  // padding-left: 15px;
  // padding-right: 15px;
  box-sizing: border-box;
  text-align: center;
  color: white;

}

.tNodeDateYellow{
  width: 50px;
  height: 50px;
  background-color: rgba(231, 174, 0, 1);
  font-size: 12px;
  color: white;

}

.timelineNodesUpload{

  width:500px;
  margin-left:70px;
  padding-right:70px;
  margin-top:60px;
  position: relative;
  z-index:8;
  height: 83vh;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  overflow-x: hidden;

  // margin-top:60px;
  //
  // position: relative;
  // z-index:8;
  // height: 107vh;
  // display: flex;
  // flex-direction: row;
  // overflow: scroll;
}

.timelineNodes{
  width: 510px;
  padding-left:80px;
  padding-right:70px;

  margin-top: 60px;
  position: relative;
  z-index: 8;
  height: 82.5vh;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: flex-start;
}

.timelineNodesLanding{
  width: 510px;
  padding-left:80px;
  padding-right:70px;
  margin-top: 60px;
  position: relative;
  z-index: 8;
  height: 82.5vh;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: flex-start;
}

#timelineNodesLeft{
  padding-top: 6px;
  width: 250px;
  height: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
}

#timelineNodesRight{
  padding-top: 45px;
  width: 250px;
  height: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;

}



.triButtonContainer{
  position: absolute;
  display: block;
  margin-top:2px;
  margin-left: 150px;
  .triButtonIcon{
    background-color: $secondary-color;
    color:black;
    width:30px;
    height:30px;
    border-radius: 50%;
    border:none;
    outline:none;
    z-index: 10;
    position: absolute;
    // transition: transform 1.3s cubic-bezier(.215,.61,.355,1),background-color 1.3s cubic-bezier(.215,.61,.355,1),-webkit-transform 1.3s cubic-bezier(.215,.61,.355,1);

  }

  .triButtonItem{
    display: block;
    position: absolute;
    border:none;
    z-index:9;
    background-color:$primary-color;
    color:white;
    width:30px;
    height:30px;
    border-radius: 50%;
    transition: transform 1.3s cubic-bezier(.215,.61,.355,1),background-color 1.3s cubic-bezier(.215,.61,.355,1),-webkit-transform 1.3s cubic-bezier(.215,.61,.355,1);


  }

    #item1{
    }
    #item2{

    }
    #item3{

    }

}

.triButtonContainer:hover{
  position: absolute;
  display: block;
  margin-left:165px;
  margin-top:17px;


  .triButtonIcon{
    background-color: $secondary-color;
    color:black;
    width:30px;
    height:30px;
    border:none;
    border-radius: 50%;
    outline:none;
    z-index: 12;
    position: absolute;
    transform: translate3d(-50%,-50%,0) ;
    // transition: transform 1.3s cubic-bezier(.215,.61,.355,1),background-color 1.3s cubic-bezier(.215,.61,.355,1),-webkit-transform 1.3s cubic-bezier(.215,.61,.355,1);

  }

  .triButtonItem{
    display: block;
    position: absolute;
    z-index: 11;

    border:none;
    background-color:$primary-color;
    color:white;
    width:30px;
    height:30px;
    border-radius: 50%;
    transition: transform 1.3s cubic-bezier(.215,.61,.355,1),background-color 1.3s cubic-bezier(.215,.61,.355,1),-webkit-transform 1.3s cubic-bezier(.215,.61,.355,1);


  }
    #item1{
      transform: translate3d(-50%,-155%,0) scale(.8);
    }
    #item2{
      transform: translate3d(35%,25%,0) scale(.8);
    }
    #item3{
      transform: translate3d(-135%,25%,0) scale(.8);
  }
}


.triButtonContainerExpand{
  position: absolute;
  display: block;
  margin-left:165px;
  margin-top:17px;

  .triButtonIcon{
    background-color: $secondary-color;
    color:black;
    width:30px;
    height:30px;
    border:none;
    border-radius: 50%;
    outline:none;
    z-index: 12;
    position: absolute;
    transform: translate3d(-50%,-50%,0) ;
    // transition: transform 1.3s cubic-bezier(.215,.61,.355,1),background-color 1.3s cubic-bezier(.215,.61,.355,1),-webkit-transform 1.3s cubic-bezier(.215,.61,.355,1);

  }

  .triButtonItem{
    display: block;
    position: absolute;
    z-index: 11;

    border:none;
    background-color:$primary-color;
    color:white;
    width:30px;
    height:30px;
    border-radius: 50%;
    transition: transform 1.3s cubic-bezier(.215,.61,.355,1),background-color 1.3s cubic-bezier(.215,.61,.355,1),-webkit-transform 1.3s cubic-bezier(.215,.61,.355,1);


  }
    #item1{
      transform: translate3d(-50%,-155%,0) scale(.8);
    }
    #item2{
      transform: translate3d(35%,25%,0) scale(.8);
    }
    #item3{
      transform: translate3d(-135%,25%,0) scale(.8);
  }
}
#triButtonContainerContent{
  position:absolute;
  top: 2px;
}

#nodeViewUserNameLink{
  color: $primary-color;
  transition-duration: 0.5s;
}

#nodeViewUserNameLink:hover{
  color: $primary-color;
  text-decoration:underline;
}

#nodePositionNavNumber{
  position:absolute;
  width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  top: 115px;
  right:-110px;
  z-index: 1200;
}
#navigateButtonTimelineContainer{
  position:absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $secondary-color;
  height:40px;
  width: 90px;
  top: 35px;
  right:-130px;
  z-index: 1200;
  transform: rotate(90deg);

  #arrowDisabled{
    background-color: $soft-black2;
    margin-top: 0px;

  }
  .navigateButtonTimeline{
    height:40px;
    width:40px;
    background-color: $primary-color;
    border-radius: 50%;
    border: 0;
    align-content: center;
    #arrowLeft{
      transform: scaleX(-1);
    }
    img{
      height:10px;
      width:10px;
    }
  }
  .navigateButtonTimeline:hover{
    height:40px;
    width:40px;
    background-color: $primary-color;
    border-radius: 50%;
    border: 0;
    margin-top:2px;
    align-content: center;

    #arrowLeft{
      transform: scaleX(-1);
    }
    img{
      height:10px;
      width:10px;
    }
  }
}


#navigateHistoryButtonTimelineContainer{
  position:absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $secondary-color;
  height:40px;
  width: 60px;
  top: 10px;
  left: -60px;
  z-index: 1200;

  #arrowDisabled{
    background-color: $soft-black2;
    margin-top: 0px;

  }
  .navigateButtonTimeline{
    height:30px;
    width:30px;
    background-color: $primary-color;
    border: 0;
    align-content: center;
    #arrowLeft{
      transform: scaleX(-1);
    }
    img{
      height:10px;
      width:10px;
    }
  }
  .navigateHistoryButtonTimeline:hover{
    height:30px;
    width:30px;
    background-color: $primary-color;
    border: 0;
    margin-top:2px;
    align-content: center;

    #arrowLeft{
      transform: scaleX(-1);
    }
    img{
      height:10px;
      width:10px;
    }
  }
}

.Modal {
  position: absolute;
  z-index: 2000;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 5px;
  color: black;
  font-size: 13px;
  background-color: #ffffff;
  #removeModalHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    color:black;
    text-indent: 10px;

    border-bottom: 1px solid $soft-white;
    height: 35px;

    background-color: #f6f7f9;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    #title{
      font-size: inherit;
      border: none;
      padding: 0;
      margin:0;
      margin-right: 5px;
      max-width: 300px;
      width:auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

  #removeModalButtonContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color:white;
    text-indent: 10px;
    border-top: 1px solid $soft-white;
    font-size: 20px;
    margin-top: 15px;
    height:35px;
  }
  #removeModalText{
    height:30px;
    font-size: 15px;
    margin:10px;
    text-indent: 0;
    max-width: 500px;
    width:auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  #removeModalButton{
    background-color: $primary-color;
    border:1px solid $medium-grey;
    color:white;

  }
  .modalButton{
    display: flex;
    flex-direction:row;
    justify-content: center;
    height: 25px;
    min-width: 50px;
    margin:10px;
    border:1px solid lightgray;
    background-color: $soft-white;
    color: $soft-black;
    border:1px solid $medium-grey;
    border-radius: 3px;
    font-family: $primary-font;
    font-size: 13px;
    transition-duration: 0.3s;
    padding-left: 3px;
    padding-right: 3px;
  }
  .modalButton:hover{
    display: flex;
    flex-direction:row;
    justify-content: center;
    height: 25px;
    min-width: 50px;
    margin:10px;

    background-color: $soft-black2;
    color: $white;
    border:1px solid $medium-grey;
    border-radius: 3px;
    font-size: 13px;
    font-family: $primary-font;
    padding-left: 3px;
    padding-right: 3px;

  }
}

.Overlay {
  position: absolute;
  top: 200px;
  right: 7.5vw;
  z-index: 2000;
  height: 130px;
  width: 500px;
  border-radius: 3px;
  text-indent: 10px;
  background-color: #f6f7f9;
  border:1px solid $medium-grey;

}

#nodeContainerLinkContainer{
  height: 160px;
  width: 175px;
  background-color: $white;
  color:black;
  word-wrap: break-word;
  word-break: break-word;
  font-size: inherit;
  padding: 5px;
  box-sizing: border-box;
  font-size: 7px;
  #linkHeader{
    font-size: 10px;
  }

  #linkDesc{
    font-size: 7px;
    color: $desc-gray;
  }
  #linkSource{
    font-size: 7px;
    color: $desc-gray;
  }
  img{
    width: 155px;
    height: 40px;
    margin-left: 5px;
  }

}
#nodeContainerTextContainer{
  height: 160px;
  width: 160px;
  background-color: $soft-green;
  color: $soft-black;
  word-wrap: break-word;
  word-break: break-word;
  font-size: inherit;
  box-sizing: border-box;
  overflow: hidden;
  #nodeContainerEmbedCover{
    font-size: 8px;
  }
  div{
    iframe{
      margin:0;
      width:160px;
      height: 170px;
    }
  }

}
#modalHiddenButton{
  display: none;
}

#timelineNodesAlternateContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 2000;
  top: 100px;
  height: 200px;
  width: 400px;
  color: $soft-white;
  background-color: $soft-black2;
  border: none;
  padding:15px;
  box-sizing: border-box;
  #timelineNodesAlternateContainerHeader{
    font-size: 16px;
    margin: 15px;
    margin-bottom:30px;
  }
}

.onBoardUploadButton{
  position: absolute;
  z-index: 10;
  border: 1px solid $soft-white;
  top: 130px;
  padding:10px;
  background:none;
  font-size: 14px;
  font-family: $primary-font;
  color: $medium-grey;
  transition-duration: 0.5s;
}
.onBoardUploadButton:hover{
  position: absolute;
  z-index: 10;
  border: 1px solid $soft-white;
  top: 130px;
  padding:10px;
  background:none;
  font-size: 14px;
  font-family: $primary-font;
  color: white;
}

.onBoardCreateButtonContainer{
  top: 130px;
  position:absolute;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .onBoardCreateButton{
    background: none;
    color:$medium-grey;
    font-family: $primary-font;
    border: 1px solid $soft-white;
    padding:10px;
    margin: 5px;
  }

}

.onBoardUploadContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
