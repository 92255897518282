#PermissionsNodeViewContainer{
  background-color: $soft-black2;
  width:610px;
  height:500px;
  margin-bottom: 10px;
}

#permissionsNodeView{
  background-color: $soft-green;
  width:610px;
  height:420px;
  margin-bottom: 10px;
  color:$soft-black2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#permissionsNodeViewHeaderContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  color:$soft-white;

  #permissionsNodeViewHeader{
    font-size: 40px;
  }
  #permissionsNodeViewHeaderDetail{
    font-size: 12px;
    top: -20px;
    right: 10px;
    position: relative;
    font-weight: 0;
    font-family: inherit;

  }
}

#permissionsNodeViewInfoContainer{
  font-weight: 0;
  font-family: inherit;
  display: flex;
  flex-direction: row;

}

#permissionsNodeViewInfoContent{
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 60px;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 0;
  color:$soft-black2;

  #headerText{
    word-wrap: break-word;
    word-break: break-word;
    font-size: inherit;
    font-size: 13px;
    margin:10px;

  }
}

#permissionsNodeViewImageContainer{
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-left: 390px;
  color:$soft-black2;

  img{
    height:350px;
    width: 150px;

  }
  #FeedRectOne{
    margin-bottom: 15px;
    margin-right: 15px;
  }

}




#permissionsSearchInput{
  width: 450px;
  height:40px;
  border-radius: 10px;
  border:none;
  font-family: $primary-font;

  background-color:$soft-white;
  font-size: 20px;
  text-indent: 8px;
  color: $soft-black2;
}

#permissionsSearchContainer{
  margin-left: 20px;
  display:flex;
}

#searchIcon{
  width:16px;
  height:16px;
}
.searchPermissionsIcon{
  margin-left: 8px;
  background-color: $primary-color;
  border-radius: 10px;
  width:50px;
  height:50px;
  border: 0;
}


#userPermissionButtonContainer{
  color:$soft-white;
  display: flex;
  flex-direction: row;
  width: 450px;
  margin-left: 20px;
  justify-content: space-between;
  align-items: center;
  #buttonContainer{
    justify-content: flex-end;
    display: flex;
    #userPermissionsButtonSelected{
      background: none;
      color: $soft-white;
      font-family: $primary-font;
      padding: 4px;
      font-size: 13px;
      border: 1px solid lightgray;
      margin-left: 2px;
      margin-right: 2px;
    }
    #userPermissionsButton{
      background: none;
      color: $soft-white;
      border: none;
      font-family: $primary-font;
      padding: 4px;
      font-size: 13px;
      margin-left: 2px;
      margin-right: 2px;
    }
    #userPermissionsButton:hover{
      background: none;
      color: $soft-white;
      border: 1px solid $soft-white;
      font-family: $primary-font;
      padding: 4px;
      font-size: 13px;
      margin-left: 2px;
      margin-right: 2px;
    }
    #userPermissionsButtonView{
      background: none;
      color: $soft-white;
      border: none;
      font-family: $primary-font;
      padding: 4px;
      font-size: 13px;
      margin-left: 2px;
      margin-right: 2px;
    }
  }
}


#userPermissionKeyContainer{
  color:$soft-white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 450px;
}

.line-containerPermissionsNodeView {
    display: flex;
    justify-content: space-between;
    position: absolute;
    height: inherit;
    right: 12%;
    left: 12%;
    z-index: 10;
}
