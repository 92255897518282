
.ExploreNodeViewContributorNodesContainer{
  background-color: #f5f5f5;
  width: 745px;
  margin-bottom: 10px;
  margin-left: 12.5px;
  padding-bottom: 12.5px;
  display: flex;
  border: 1px solid $light-grey;
  flex-direction: column;
  align-items: center;
  .ExploreNodeViewContributorNodesOverflowContainer{
    overflow-y: scroll;
    display: flex;
    // height: 380px;
    flex-direction: column;
    padding-left: 20px;
    padding-right:20px;
    align-items: center;
  }
  .ExploreNodeViewContributorNodesContainerTitle{
    width: 90%;
    margin-top:15px;
    font-size: 11px;
    margin-left: 25px;
    margin-right: 25px;
    text-align: center;
    color:black;
    border-bottom: 1px solid $medium-grey;
  }
  #exploreNodeViewContributorNode{
    width: 180px;
    height: 200px;
    background-color: #FFFFFF;
    border: 1px solid #d5d3d3;
    padding: 5px;
    box-sizing: border-box;
    -moz-box-sizing:border-box;
-webkit-box-sizing:border-box;
 display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition-duration: 0.3s;

    #contributorNodePreview {
    width: 130px;
    height: 90px;
    margin: 5px;
    overflow: hidden;
  }
    #contributorNodeImage{
      height:90px;
      width:130px;
      transition-duration: 0.5s;
    }
    #exploreNodeViewContributorNodeContent{
      color: black;
      font-size: 13px;
      display: flex;

      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      #exploreNodeViewContributorUpvoteContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        transform: scale(0.8);
      }
      #exploreNodeViewContributorButtonContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;

        button{
          background: none;
          border:none;
          width: 30px;
          height: 20px;
        }
        button:hover{
          opacity: 0.7;
          background: none;
          border:none;
          width: 30px;
          height: 20px;
        }
      }
    }
    .exploreNodeViewContributorNodeClickthrough{
      background-color: $soft-black2;
      padding: 6px;
      width: 80px;
      color: white;
      font-family: $primary-font;
      font-size: 9.5px;
      border: none;
      border-radius: 4px;
      transition-duration: 0.3s;
    }

  }
  }
  #exploreNodeViewContributorNode:hover{
    width: 180px;
    height: 200px;
    background-color: $light-grey;
    border: 1px solid #d5d3d3;
    padding: 5px;
    box-sizing: border-box;
-moz-box-sizing:border-box;
-webkit-box-sizing:border-box;
display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition-duration: 0.3s;


    #contributorNodeImage{
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
      transition-duration: 0.5s;
    }
    #exploreNodeViewContributorNodeContent{
      color: black;
      font-size: 13px;
      display: flex;

      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      #exploreNodeViewContributorUpvoteContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      #exploreNodeViewContributorButtonContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        button{
          background: none;
          border:none;
          width: 30px;
          height: 20px;
        }
        button:hover{
          opacity: 0.7;
          background: none;
          border:none;
          width: 30px;
          height: 20px;
        }
      }
    }
    .exploreNodeViewContributorNodeClickthrough{
      background-color: $primary-color;
      padding: 6px;
      width: 80px;
      color: white;
      font-family: $primary-font;
      font-size: 9.5px;

      border: none;
      border-radius: 4px;
    }


  }

    #exploreNodeViewContributorNodeLarge{
      width: 360px;
      height: 400px;
      background-color: #FFFFFF;
      border: 1px solid #d5d3d3;
      padding: 5px;
      padding-top: 15px;
      box-sizing: border-box;
-moz-box-sizing:border-box;
-webkit-box-sizing:border-box;
 display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      transition-duration: 0.3s;


        #contributorNodeLargePreview {
        max-width: 260px;
        width:auto;

        height: 180px;
        margin:10px;
        overflow: hidden;
      }
      #contributorNodeImage{
        max-width: 260px;
        width:auto;
        max-height:180px;
        transition-duration: 0.5s;

      }
      #exploreNodeViewContributorNodeContent{
        color: black;
        font-size: 13px;
        display: flex;

        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        #exploreNodeViewContributorUpvoteContainer{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          transform: scale(0.8);
        }
        #exploreNodeViewContributorButtonContainer{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-size: 13px;

          button{
            background: none;
            border:none;
            width: 30px;
            height: 20px;
          }
          button:hover{
            opacity: 0.7;
            background: none;
            border:none;
            width: 30px;
            height: 20px;
          }
        }
      }
      .exploreNodeViewContributorNodeClickthrough{
        background-color: $soft-black2;
        padding: 6px;
        width: 80px;
        color: white;
        font-family: $primary-font;
        font-size: 9.5px;

        border: none;
        border-radius: 4px;
        transition-duration: 0.3s;
      }

    }


    #exploreNodeViewContributorNodeLarge:hover{
      width: 360px;
      height: 400px;
      background-color: $light-grey;
      border: 1px solid #d5d3d3;
      padding: 5px;
      padding-top: 15px;
      box-sizing: border-box;
-moz-box-sizing:border-box;
-webkit-box-sizing:border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      transition-duration: 0.3s;

    #contributorNodeImage{
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
      transition-duration: 0.5s;

    }
      #exploreNodeViewContributorNodeContent{
        color: black;
        font-size: 13px;
        display: flex;

        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        #exploreNodeViewContributorUpvoteContainer{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          transform: scale(0.8);
        }
        #exploreNodeViewContributorButtonContainer{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-size: 13px;

          button{
            background: none;
            border:none;
            width: 30px;
            height: 20px;
          }
          button:hover{
            opacity: 0.7;
            background: none;
            border:none;
            width: 30px;
            height: 20px;
          }
        }
      }
      .exploreNodeViewContributorNodeClickthrough{
        background-color: $primary-color;
        padding: 6px;
        width: 80px;
        color: white;
        font-family: $primary-font;
        font-size: 9.5px;
        border: none;
        border-radius: 4px;
        transition-duration: 0.3s;
      }

    }
#exploreNodeViewHeaderContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;


  #exploreNodeViewHeader{
    #exploreNodeViewHeaderHead{
      font-size: 25px;
      color: black;

    }
    #exploreNodeViewHeaderSub{
      font-size: 10px;
      color: $desc-gray;
    }
    #exploreNodeViewHeaderDesc{
      margin-top: 15px;
      font-size: 10px;
      color: black;
      width: 370px;
      padding-left:5px;
      border-left: 1px solid $medium-grey;


    }
  }

  #exploreNodeViewHeaderDetail{
    font-size: 10px;
    color: black;
    top: -10px;
    right: 10px;
    position: relative;
    font-weight: 0;
    font-family: inherit;

  }
}
#exploreNodeViewHeaderDescription{
  font-size: 11px;
  color:$medium-grey;
  margin-left: 30px;
  transition-duration: 0.3s;
  margin-top: 5px;
  margin-bottom:10px;
}
#exploreNodeViewHeaderDescription:hover{
  font-size: 11px;
  color:$soft-white;
  margin-left: 30px;
  margin-top: 5px;
  margin-bottom:10px;
}

#explorePageOnboardText{
  font-size: 15px;
  width: 550px;
  margin-top: 20px;
  margin-left: 30px;
}

#exploreNodeViewContributorNodeInfo{
  height: 55px;
  border-top: 1px solid $medium-grey;
  margin:5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  p{
    text-align: center;
  }
  #nodeUsername{
    text-align: center;
    font-size: 10px;
    color: $dark-grey;
  }
}

#ExploreNodesRowFour{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 720px;
}

#ExploreNodesRowFive{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 720px;
}
#ExploreNodesRowFiveBox{
  display: flex;
  flex-direction: row;
}

#ExploreNodesRowFiveBoxRow{
  display: flex;
  flex-direction: row;
}
#exploreContentContainer{
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  width:800px;

}

.line-containerExploreNodeView {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: absolute;
    width: 790px;
    height: 235px;
    right: 12%;
    left: 12%;
    z-index: 10;
}

.exploreQueryNodeView{
  display: flex;
  flex-direction: column;
  height: inherit;
  color: $soft-white;
  z-index: 10;
  font-size: 12px;
  padding-bottom: 10px;
  margin-left: 10px;
}

.ExploreNodeViewContainer{
  background-color: $soft-black2;
  width:790px;
  margin-bottom: 10px;
  min-height: 200px;
}

#eNodeViewContainer{
  width:800px;
  background-color: white;
  border:1px solid $medium-grey;
  top: -1px;
  position: relative;
  box-sizing: content-box;
  margin-bottom: 30px;
}


#exploreTimelineViewBox {
  background: none;
  width: 450px;
  margin:0;
  padding:0;
}

.exploreViewBox {
  display: flex;
  flex-direction: column;
  width:825px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 40px);
  position: relative;
  color: black;

}

#pageGridExploreTopics{
  width: 450px;
  margin:0;
  padding: 0;
}

#ExploreNodesQueryHeader{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 718px;
  height: 140px;
  background: white;
  border: 1px solid #d5d3d3;
  border-top: none;
}


#exploreSearchInput{
  width: 230px;
  height:25px;
  border-radius: 3px;
  border:none;
  font-family: $primary-font;
  background-color:$soft-white;
  font-size: 11px;
  text-indent: 8px;
  color: $soft-black2;
}

#exploreSearchContainer{
  margin-top: 5px;
}


.searchExploreIcon{
  margin-left: 8px;
  background-color: $primary-color;
  border-radius: 5px;
  width:25px;
  height:25px;
  border: 0;
  #searchIcon{
    width:10px;
    height:10px;
  }

}
#timelineRootExplore{
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  top:5px;
  left: 16px;
}

#timelineLineExplore{
  height: 91.5vh;
}

.timelineNodePreviewButtonExplore{
  position:relative;
  top:-30px;

  z-index:100;
  border:none;
  text-align: left;
  left:-17.5px;
  height:120px;

  .nodeContainerContent{
    height: 120px;
    width: 100px;
    border: 1px solid $light-grey;
    background-color: white;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }

  .timelineNodePreview{
    max-width: 100px;
    height: auto;
    max-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: 5px;
    transition-duration: 0.5s;

    img{
      width:90px;
      height: auto;
      max-height: 80px;
      transition-duration: 0.5s;
      -webkit-transition-duration: 0.5s;
      -moz-transition-duration: 0.5s;
      -ms-transition-duration: 0.5s;
      -o-transition-duration: 0.5s;
      background-image: url('/public/images/imageDefault.jpg');
      background-size: contain;
      text-align: center;
      color:white;
    }
    overflow: hidden;
  }

}

.timelineNodePreviewButtonExplore:hover{
  position:relative;
  top:-30px;
  z-index:100;
  border:none;
  text-align: left;
  height:120px;
  .nodeContainerContent{
    height: 120px;
    width: 100px;
    border: 1px solid $light-grey;
    background-color: white;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }

  .timelineNodePreview{
    max-width: 100px;
    height: auto;
    max-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: 5px;
    transition-duration: 0.5s;

    img{
      width:90px;
      height: auto;
      max-height: 80px;
      transition-duration: 0.5s;
      -webkit-transition-duration: 0.5s;
      -moz-transition-duration: 0.5s;
      -ms-transition-duration: 0.5s;
      -o-transition-duration: 0.5s;
      background-image: url('/public/images/imageDefault.jpg');
      background-size: contain;
      text-align: center;
      color:white;
    }
    overflow: hidden;
  }
}


.timelineNodesExplore{
  width: 470px;
  margin-top: 5px;
  position: relative;
  z-index: 8;
  height: 91.5vh;

  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: flex-start;

}

#pageGridExplore{
  margin:0;
  padding: 0;
}
.timelineNodesContainer{
  margin-left: -14px;
}


.nodeContainerTitleExploreTopic{
  margin: 3px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  font-size: 13.5px;
  flex-direction: row;
  align-items: center;
  width: 100px;
  height:30px;
  div{
    width: 100px;
    left:5px;
    position: absolute;
    font-size: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

#exploreTimelineRootConnectorContainer{
position:relative;
left: -27.5px;
top:30px;
  .exploreTimelineRootNodeConnectorCircle{
    border: 2px solid $secondary-color;
    background: black;
    width:4px;
    height:4px;
    top:4px;
    border-radius: 50%;
    left: -12.5px;
    position: relative;

  }
  .exploreTimelineRootNodeConnectorDotLine{
    border-bottom: 1px dotted $medium-grey;
    width:7px;
    left:-6px;
    position: relative;

  }
  .exploreTimelineRootNodeConnectorDotLine2{
    border-bottom: 1px dotted $medium-grey;
    border-left: 1px dotted $medium-grey;
    width:7px;
    height:30px;
    z-index: 4;
    position: relative;
  }
}

#timelineNodeExploreTopicContainer{

  display:block;
  margin-bottom: 20px;
}


#timelineNodesExplore{
  padding-top: 6px;
  width: 150px;
  padding-left: 57px;
  box-sizing: content-box;
  height: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
}

#exploreFeedContainer{
  padding-top: 6px;
  padding-bottom: 20px;
  width: 290px;
  position: fixed;
  top:50px;
  border: 1px solid $light-grey;
  margin-left: 165px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#exploreFeedNode{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  background: none;
  border: none;
  transition-duration: 0.5s;

  #exploreFeedNodeNum{
    font-size: 22px;
    color:#d7d7d7;
    margin:10px;
    position: relative;
    top:-15px;
    transition-duration: 0.5s;

  }
  #exploreFeedNodeContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    #exploreFeedNodeHeader{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;
          width: 240px;

      div{
        margin-left: 8px;
        font-size: 15px;
        text-align: left;
      }
    }
    #exploreFeedNodeDesc{
      width: 220px;
      font-size: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 11px;
      font-family: "Lucida Grande","Lucida Sans Unicode","Lucida Sans", Arial,sans-serif!important;
      margin-bottom: 5px;
      text-align: left;

    }
    #exploreFeedNodeInfo{
      font-size: 9px;
      color:#aeaeae;
      font-family: "Lucida Grande","Lucida Sans Unicode","Lucida Sans", Arial,sans-serif!important;
    }
  }
}
#exploreFeedNode:hover{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 110px;
  background: none;
  border: none;
  background-color: $light-grey;
  #exploreFeedNodeNum{
    font-size: 22px;
    color:$soft-black2;
    margin:10px;
    position: relative;
    top:-18px;
  }

}
#exploreFeedHeader{
  font-size: 22px;
  border-bottom: 1px solid $light-grey;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-bottom:10px;
}

#exploreNodeViewHeaderImage{
  margin:10px;
  width: 200px;
  height:120px;
  margin-left: 110px;
}
